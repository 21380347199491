import React, { useState } from 'react';
import logo from '../img/logo.png'; // Asegúrate de importar el logo

const FormularioInicial = ({ onSubmit, isLoading }) => {
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    edad: '',
    pais: '',
    industria: '',
    tipoTrabajo: '',
    fechaRegistro: new Date().toISOString(),
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const paises = [
    "Argentina", "Bolivia", "Brasil", "Chile", "Colombia", "Costa Rica", 
    "Cuba", "Ecuador", "El Salvador", "España", "Estados Unidos", "Guatemala", 
    "Honduras", "México", "Nicaragua", "Panamá", "Paraguay", "Perú", 
    "Puerto Rico", "República Dominicana", "Uruguay", "Venezuela"
  ];

  const industrias = [
    "Agricultura y Ganadería",
    "Alimentación y Bebidas",
    "Arte y Entretenimiento",
    "Automotriz",
    "Banca y Servicios Financieros",
    "Biotecnología",
    "Comercio Minorista",
    "Construcción",
    "Consultoría",
    "Ciencia y Tecnología",
    "Estudios Legales",
    "Educación",
    "Energía y Recursos Naturales",
    "Farmacéutica",
    "Gobierno y Sector Público",
    "Hostelería y Turismo",
    "Inmobiliaria",
    "Investigación y Desarrollo",
    "Logística y Transporte",
    "Manufactura",
    "Marketing y Publicidad",
    "Medios de Comunicación",
    "Moda y Textil",
    "ONG y Organizaciones sin Fines de Lucro",
    "Salud y Servicios Médicos",
    "Seguridad",
    "Servicios Legales",
    "Tecnología",
    "Software",
    "Creador de contenido",
    "Telecomunicaciones",
    
    "Otra"
  ];

  const tiposTrabajo = [
    "Trabajador Dependiente",
    "Trabajador Independiente",
    "Freelancer"
  ];

  return (
    <form onSubmit={handleSubmit} className="registro-form">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div>
        <label>Nombre y Apellido:</label>
        <input type="text" name="nombre" value={formData.nombre} onChange={handleChange} required />
      </div>
      <div>
        <label>Email:</label>
        <input type="email" name="email" value={formData.email} onChange={handleChange} required />
      </div>
      <div>
        <label>Edad:</label>
        <input type="number" name="edad" value={formData.edad} onChange={handleChange} required />
      </div>
      <div>
        <label>País:</label>
        <select name="pais" value={formData.pais} onChange={handleChange} required>
          <option value="">Seleccione un país</option>
          {paises.map((pais, index) => (
            <option key={index} value={pais}>{pais}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Industria:</label>
        <select name="industria" value={formData.industria} onChange={handleChange} required>
          <option value="">Seleccione una industria</option>
          {industrias.map((industria, index) => (
            <option key={index} value={industria}>{industria}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Tipo de Trabajo:</label>
        <select name="tipoTrabajo" value={formData.tipoTrabajo} onChange={handleChange} required>
          <option value="">Seleccione tipo de trabajo</option>
          {tiposTrabajo.map((tipo, index) => (
            <option key={index} value={tipo}>{tipo}</option>
          ))}
        </select>
      </div>
      <button type="submit" disabled={isLoading}>
        {isLoading ? <span className="spinner"></span> : 'Enviar'}
      </button>
    </form>
  );
};

export default FormularioInicial;
