import React from "react";
import { Calendar, CheckSquare, Mic, Layers, Clock, Lightbulb, Bot, Shuffle, Share2, BarChart2, Clipboard, Mail } from "lucide-react";
import './caracteristicas.css';

function Caracteristicas() {
  const caracteristicas = [
    {
      icon: <Bot className="caracteristicas-icon" />,
      title: "Habla con tu Calendario",
      text: "Crea eventos, tareas y reuniones usando lenguaje natural, simplemente conversando con tu calendario."
    },
    {
      icon: <Calendar className="caracteristicas-icon" />,
      title: "Sincronización de Calendarios",
      text: "Integra todos tus calendarios en un solo lugar, simplificando la gestión de tu tiempo y evitando conflictos."
    },
    {
      icon: <CheckSquare className="caracteristicas-icon" />,
      title: "Sensación de Logro",
      text: "Marca tareas completadas para aumentar tu motivación y percepción de progreso."
    },
    {
      icon: <Layers className="caracteristicas-icon" />,
      title: "Clasificación Automática",
      text: "Organiza inteligentemente tus actividades en las áreas correspondientes de tu vida."
    },
    {
      icon: <Clock className="caracteristicas-icon" />,
      title: "Ahorro de Tiempo",
      text: "Optimiza tu rutina diaria reduciendo el tiempo dedicado a organizar tu agenda."
    },
    {
      icon: <Lightbulb className="caracteristicas-icon" />,
      title: "Banco de Ideas",
      text: "Guarda ideas pendientes sin fecha para tenerlas presentes y actuar en el corto plazo."
    },
    // Añade el resto de características aquí...
  ];

  return (
    <div className="caracteristicas-container">
      <h1 className="caracteristicas-title">Características y Beneficios</h1>
      <div className="caracteristicas-tarjetas">
        {caracteristicas.map((caracteristica, index) => (
          <div key={index} className="caracteristicas-card">
            {caracteristica.icon}
            <h4 className="caracteristicas-titleCard">{caracteristica.title}</h4>
            <p className="caracteristicas-text">{caracteristica.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Caracteristicas;
