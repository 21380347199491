import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { loginWithGoogle } from '../services/auth';
import { Bar, Pie } from 'react-chartjs-2';
import { ClipboardCheck, X } from 'lucide-react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import './Stats.css';
import Logo from '../img/logo.png';
import { useReactTable, getCoreRowModel, getPaginationRowModel, getFilterModel, flexRender } from '@tanstack/react-table';
import * as XLSX from 'xlsx';
import { format, parseISO, getDay } from 'date-fns';
import { es } from 'date-fns/locale';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Stats = () => {
  const [respuestas, setRespuestas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [estadisticas, setEstadisticas] = useState({
    totalUsuarios: 0,
    edadPromedio: 0,
    distribucionPaises: {},
    distribucionIndustrias: {},
    distribucionEdades: {},
    respuestasExtras: 0,
    porcentajeRespuestasExtras: 0,
    palabrasComunes: {
      organizacion: {},
      herramientas: {},
      frustraciones: {},
      impacto: {},
      bienestar: {}
    },
    distribucionTipoTrabajo: {},
    porcentajesTipoTrabajo: {},
  });
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const obtenerRespuestas = async () => {
      if (!user) return;

      const emailsAutorizados = [
        'aljerr3@gmail.com',
        'davidavila@gmail.com',
        'adrianacepeda11@gmail.com'
      ];

      if (!emailsAutorizados.includes(user.email)) {
        navigate('/');
        return;
      }

      try {
        const querySnapshot = await getDocs(collection(db, "respuestas"));
        const datos = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setRespuestas(datos);
        calcularEstadisticas(datos);
      } catch (error) {
        console.error("Error al obtener respuestas:", error);
      }
    };

    obtenerRespuestas();
  }, [user, navigate]);

  const calcularEstadisticas = (datos) => {
    const datosConFecha = datos.map(dato => ({
      ...dato,
      fechaRegistro: dato.fechaRegistro || '2024-11-13T00:00:00.000Z'
    }));

    const totalUsuarios = datosConFecha.length;
    const respuestasExtras = datosConFecha.filter(d => d.preguntasAdicionales).length;
    
    // Calcular edad promedio
    const edadPromedio = datosConFecha.reduce((acc, curr) => acc + parseInt(curr.edad), 0) / totalUsuarios;
    
    // Distribución por países
    const distribucionPaises = datosConFecha.reduce((acc, curr) => {
      acc[curr.pais] = (acc[curr.pais] || 0) + 1;
      return acc;
    }, {});

    // Distribución por industrias
    const distribucionIndustrias = datosConFecha.reduce((acc, curr) => {
      acc[curr.industria] = (acc[curr.industria] || 0) + 1;
      return acc;
    }, {});

    // Distribución por rangos de edad
    const distribucionEdades = datosConFecha.reduce((acc, curr) => {
      const edad = parseInt(curr.edad);
      if (edad < 25) acc['18-24'] = (acc['18-24'] || 0) + 1;
      else if (edad < 35) acc['25-34'] = (acc['25-34'] || 0) + 1;
      else if (edad < 45) acc['35-44'] = (acc['35-44'] || 0) + 1;
      else acc['45+'] = (acc['45+'] || 0) + 1;
      return acc;
    }, {});

    // Distribución por tipo de trabajo
    const distribucionTipoTrabajo = datosConFecha.reduce((acc, curr) => {
      const tipo = curr.tipoTrabajo || 'No definido';
      acc[tipo] = (acc[tipo] || 0) + 1;
      return acc;
    }, {});

    // Calculamos los porcentajes
    const porcentajesTipoTrabajo = {};
    Object.keys(distribucionTipoTrabajo).forEach(tipo => {
      porcentajesTipoTrabajo[tipo] = Math.round((distribucionTipoTrabajo[tipo] / totalUsuarios) * 100);
    });

    // Analizar palabras comunes en respuestas adicionales
    const palabrasComunes = {
      organizacion: analizarPalabrasComunes(datosConFecha, 'organizacion'),
      herramientas: analizarPalabrasComunes(datosConFecha, 'herramientas'),
      frustraciones: analizarPalabrasComunes(datosConFecha, 'frustraciones'),
      impacto: analizarPalabrasComunes(datosConFecha, 'impacto'),
      bienestar: analizarPalabrasComunes(datosConFecha, 'bienestar')
    };

    // Agrupar registros por mes
    const registrosPorMes = datosConFecha.reduce((acc, curr) => {
      const fecha = parseISO(curr.fechaRegistro);
      const mes = format(fecha, 'MMMM yyyy', { locale: es });
      acc[mes] = (acc[mes] || 0) + 1;
      return acc;
    }, {});

    // Distribución por día de la semana
    const distribucionDias = datosConFecha.reduce((acc, curr) => {
      const fecha = parseISO(curr.fechaRegistro);
      const dia = format(fecha, 'EEEE', { locale: es }); // Obtiene el nombre del día en español
      acc[dia] = (acc[dia] || 0) + 1;
      return acc;
    }, {});

    // Ordenar los días de la semana correctamente
    const ordenDias = [
      'lunes', 'martes', 'miércoles', 'jueves', 
      'viernes', 'sábado', 'domingo'
    ];
    const distribucionDiasOrdenada = ordenDias.reduce((acc, dia) => {
      acc[dia] = distribucionDias[dia] || 0;
      return acc;
    }, {});

    setEstadisticas({
      totalUsuarios,
      edadPromedio: Math.round(edadPromedio * 10) / 10,
      distribucionPaises,
      distribucionIndustrias,
      distribucionEdades,
      respuestasExtras,
      porcentajeRespuestasExtras: Math.round((respuestasExtras / totalUsuarios) * 100),
      palabrasComunes,
      registrosPorMes,
      distribucionDias: distribucionDiasOrdenada,
      distribucionTipoTrabajo,
      porcentajesTipoTrabajo,
    });
  };

  const analizarPalabrasComunes = (datos, campo) => {
    const palabras = datos
      .filter(d => d.preguntasAdicionales && d.preguntasAdicionales[campo])
      .map(d => d.preguntasAdicionales[campo].toLowerCase())
      .join(' ')
      .split(/\s+/)
      .filter(palabra => palabra.length > 3)
      .reduce((acc, palabra) => {
        acc[palabra] = (acc[palabra] || 0) + 1;
        return acc;
      }, {});

    return Object.entries(palabras)
      .sort(([,a], [,b]) => b - a)
      .slice(0, 5)
      .reduce((acc, [palabra, count]) => {
        acc[palabra] = count;
        return acc;
      }, {});
  };

  const Modal = ({ data, onClose }) => (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          <X size={24} />
        </button>
        <h3>Respuestas adicionales de {data.nombre}</h3>
        <div className="modal-grid">
          <div className="modal-item">
            <h4>Frecuencia</h4>
            <p>{data.preguntasAdicionales.organizacion}</p>
          </div>
          <div className="modal-item">
            <h4>Métodos</h4>
            <p>{data.preguntasAdicionales.herramientas}</p>
          </div>
          <div className="modal-item">
            <h4>Frustraciones</h4>
            <p>{data.preguntasAdicionales.frustraciones}</p>
          </div>
          <div className="modal-item">
            <h4>Impacto</h4>
            <p>{data.preguntasAdicionales.impacto}</p>
          </div>
          <div className="modal-item">
            <h4>Bienestar</h4>
            <p>{data.preguntasAdicionales.bienestar}</p>
          </div>
          <div className="modal-item">
            <h4>Comentarios adicionales</h4>
            <p>{data.preguntasAdicionales.comentarios}</p>
          </div>
        </div>
      </div>
    </div>
  );

  const dataPaises = {
    labels: Object.keys(estadisticas.distribucionPaises),
    datasets: [{
      label: 'Usuarios por País',
      data: Object.values(estadisticas.distribucionPaises),
      backgroundColor: 'rgba(54, 162, 235, 0.5)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 1,
    }]
  };

  const dataIndustrias = {
    labels: Object.keys(estadisticas.distribucionIndustrias),
    datasets: [{
      data: Object.values(estadisticas.distribucionIndustrias),
      backgroundColor: [
        'rgba(255, 99, 132, 0.5)',
        'rgba(54, 162, 235, 0.5)',
        'rgba(255, 206, 86, 0.5)',
        'rgba(75, 192, 192, 0.5)',
        'rgba(153, 102, 255, 0.5)',
      ],
      borderWidth: 1,
    }]
  };

  const dataEdades = {
    labels: Object.keys(estadisticas.distribucionEdades),
    datasets: [{
      label: 'Distribución por Edad',
      data: Object.values(estadisticas.distribucionEdades),
      backgroundColor: 'rgba(75, 192, 192, 0.5)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    }]
  };

  const dataRegistrosPorMes = {
    labels: Object.keys(estadisticas.registrosPorMes || {}),
    datasets: [{
      label: 'Registros por Mes',
      data: Object.values(estadisticas.registrosPorMes || {}),
      backgroundColor: 'rgba(75, 192, 192, 0.5)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    }]
  };

  const dataDias = {
    labels: Object.keys(estadisticas.distribucionDias || {}),
    datasets: [{
      label: 'Registros por Día de la Semana',
      data: Object.values(estadisticas.distribucionDias || {}),
      backgroundColor: 'rgba(153, 102, 255, 0.5)',
      borderColor: 'rgba(153, 102, 255, 1)',
      borderWidth: 1,
    }]
  };

  const dataTipoTrabajo = {
    labels: Object.keys(estadisticas.distribucionTipoTrabajo),
    datasets: [{
      data: Object.values(estadisticas.distribucionTipoTrabajo),
      backgroundColor: [
        'rgba(255, 159, 64, 0.5)',
        'rgba(54, 162, 235, 0.5)',
        'rgba(75, 192, 192, 0.5)',
        'rgba(153, 102, 255, 0.5)'
      ],
      borderWidth: 1,
    }]
  };

  const handleLogin = async () => {
    try {
      await loginWithGoogle();
    } catch (error) {
      alert('No tienes autorización para acceder a las estadísticas');
      navigate('/');
    }
  };

  const TablaRegistros = ({ data }) => {
    const [filtering, setFiltering] = useState('');
    
    const columns = [
      {
        header: 'Nombre',
        accessorKey: 'nombre',
      },
      {
        header: 'Email',
        accessorKey: 'email',
      },
      {
        header: 'País',
        accessorKey: 'pais',
      },
      {
        header: 'Industria',
        accessorKey: 'industria',
      },
      {
        header: 'Tipo de Trabajo',
        accessorKey: 'tipoTrabajo',
        cell: ({ getValue }) => getValue() || 'No definido',
      },
      {
        header: 'Edad',
        accessorKey: 'edad',
      },
      {
        header: 'Fecha de Registro',
        accessorKey: 'fechaRegistro',
        cell: ({ getValue }) => {
          const fecha = parseISO(getValue() || '2024-11-13T00:00:00.000Z');
          return format(fecha, 'dd/MM/yyyy', { locale: es });
        }
      },
      {
        header: 'Respuestas Extra',
        cell: ({ row }) => (
          row.original.preguntasAdicionales ? (
            <button 
              className="icon-button"
              onClick={() => setModalData(row.original)}
            >
              <ClipboardCheck size={20} color="#4285f4" />
            </button>
          ) : (
            <X size={20} color="#999" />
          )
        ),
      },
    ];

    const table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      initialState: {
        pagination: {
          pageSize: 20,
        },
      },
    });

    const exportToExcel = () => {
      const workbook = XLSX.utils.book_new();
      
      // Datos básicos con fecha
      const basicData = data.map(item => ({
        Nombre: item.nombre,
        Email: item.email,
        País: item.pais,
        Industria: item.industria,
        'Tipo de Trabajo': item.tipoTrabajo || 'No definido',
        Edad: item.edad,
        'Fecha de Registro': format(
          parseISO(item.fechaRegistro || '2024-11-13T00:00:00.000Z'),
          'dd/MM/yyyy',
          { locale: es }
        ),
        'Tiene Respuestas Extra': item.preguntasAdicionales ? 'Sí' : 'No'
      }));
      
      const basicWs = XLSX.utils.json_to_sheet(basicData);
      XLSX.utils.book_append_sheet(workbook, basicWs, "Datos Básicos");

      // Respuestas adicionales
      const extraData = data
        .filter(item => item.preguntasAdicionales)
        .map(item => ({
          Nombre: item.nombre,
          Email: item.email,
          'Frecuencia': item.preguntasAdicionales?.organizacion || '',
          'Métodos': item.preguntasAdicionales?.herramientas || '',
          'Frustraciones': item.preguntasAdicionales?.frustraciones || '',
          'Impacto en Objetivos': item.preguntasAdicionales?.impacto || '',
          'Impacto en Bienestar': item.preguntasAdicionales?.bienestar || '',
          'Comentarios Adicionales': item.preguntasAdicionales?.comentarios || ''
        }));
      
      // Configurar el ancho de las columnas para mejor legibilidad
      const wscols = [
        {wch: 20}, // Nombre
        {wch: 25}, // Email
        {wch: 40}, // Frecuencia
        {wch: 40}, // Métodos
        {wch: 40}, // Frustraciones
        {wch: 40}, // Impacto
        {wch: 40}, // Bienestar
        {wch: 40}  // Comentarios
      ];

      const extraWs = XLSX.utils.json_to_sheet(extraData);
      extraWs['!cols'] = wscols; // Aplicar anchos de columna

      XLSX.utils.book_append_sheet(workbook, extraWs, "Respuestas Adicionales");

      // Agregar una hoja de resumen
      const resumenData = [
        {
          'Métrica': 'Total de Usuarios',
          'Valor': data.length
        },
        {
          'Métrica': 'Usuarios con Respuestas Extra',
          'Valor': data.filter(item => item.preguntasAdicionales).length
        },
        {
          'Métrica': 'Porcentaje de Respuestas Extra',
          'Valor': `${Math.round((data.filter(item => item.preguntasAdicionales).length / data.length) * 100)}%`
        }
      ];

      const resumenWs = XLSX.utils.json_to_sheet(resumenData);
      XLSX.utils.book_append_sheet(workbook, resumenWs, "Resumen");

      // Descargar el archivo
      XLSX.writeFile(workbook, "registros_planius.xlsx");
    };

    return (
      <div className="table-container">
        <div className="table-header">
          <h3>Detalle de Registros</h3>
          <div className="table-actions">
            <input
              type="text"
              value={filtering}
              onChange={e => setFiltering(e.target.value)}
              placeholder="Buscar..."
              className="search-input"
            />
            <button onClick={exportToExcel} className="export-button">
              Exportar a Excel
            </button>
          </div>
        </div>

        <table className="stats-table">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <span>
            Página{' '}
            <strong>
              {table.getState().pagination.pageIndex + 1} de{' '}
              {table.getPageCount()}
            </strong>
          </span>
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
        </div>
      </div>
    );
  };

  if (loading) {
    return <div className="loading">Cargando...</div>;
  }

  if (!user) {
    return (
      <div className="login-container">
        <img src={Logo} alt="Logo" className="login-logo" />
        <h2>Acceso a Estadísticas</h2>
        <p>Inicia sesión para ver las estadísticas de usuarios</p>
        <button onClick={handleLogin} className="login-button">
          Iniciar sesión con Google
        </button>
      </div>
    );
  }

  return (
    <div className="stats-container">
      <h1>Panel de Estadísticas</h1>
      
      <div className="stats-cards">
        <div className="stat-card">
          <h3>Total Usuarios</h3>
          <p className="stat-number">{estadisticas.totalUsuarios}</p>
        </div>
        <div className="stat-card">
          <h3>Edad Promedio</h3>
          <p className="stat-number">{estadisticas.edadPromedio}</p>
        </div>
        <div className="stat-card">
          <h3>Respuestas Extra</h3>
          <p className="stat-number">{estadisticas.respuestasExtras}</p>
        </div>
        <div className="stat-card">
          <h3>% Respuestas Extra</h3>
          <p className="stat-number">{estadisticas.porcentajeRespuestasExtras}%</p>
        </div>
        {['Freelancer', 'Independiente', 'Dependiente', 'No definido'].map(tipo => (
          <div className="stat-card" key={tipo}>
            <h3>% {tipo}</h3>
            <p className="stat-number">{estadisticas.porcentajesTipoTrabajo[tipo] || 0}%</p>
          </div>
        ))}
      </div>

      <div className="insights-container">
        <h2>Insights de Respuestas Adicionales</h2>
        <div className="insights-grid">
          <div className="insight-card">
            <h3>Frecuencia</h3>
            <ul>
              {Object.entries(estadisticas.palabrasComunes.organizacion).map(([palabra, count]) => (
                <li key={palabra}>{palabra}: {count}</li>
              ))}
            </ul>
          </div>
          {/* Repetir para herramientas, frustraciones, impacto y bienestar */}
        </div>
      </div>

      <div className="charts-container">
        <div className="chart-wrapper">
          <h3>Distribución por País</h3>
          <Bar data={dataPaises} options={{ maintainAspectRatio: false }} />
        </div>
        
        <div className="chart-wrapper">
          <h3>Distribución por Industria</h3>
          <Pie data={dataIndustrias} options={{ maintainAspectRatio: false }} />
        </div>

        <div className="chart-wrapper">
          <h3>Distribución por Edad</h3>
          <Bar data={dataEdades} options={{ maintainAspectRatio: false }} />
        </div>

        <div className="chart-wrapper">
          <h3>Registros por Mes</h3>
          <Bar 
            data={dataRegistrosPorMes} 
            options={{ 
              maintainAspectRatio: false,
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    stepSize: 1
                  }
                }
              }
            }} 
          />
        </div>

        <div className="chart-wrapper">
          <h3>Registros por Día de la Semana</h3>
          <Bar 
            data={dataDias} 
            options={{ 
              maintainAspectRatio: false,
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    stepSize: 1
                  }
                },
                x: {
                  ticks: {
                    autoSkip: false
                  }
                }
              },
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      return `${context.parsed.y} registros`;
                    }
                  }
                }
              }
            }} 
          />
        </div>

        <div className="chart-wrapper">
          <h3>Distribución por Tipo de Trabajo</h3>
          <Pie data={dataTipoTrabajo} options={{ maintainAspectRatio: false }} />
        </div>

      </div>

      <TablaRegistros data={respuestas} />

      {modalData && (
        <Modal data={modalData} onClose={() => setModalData(null)} />
      )}
    </div>
  );
};

export default Stats;
