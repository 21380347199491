import React from "react";
import './comoFunciona.css';

function ComoFunciona() {
  return (
    <div>
      <h1 className="comoFunciona-title">¡Vence la fricción en el proceso de planificación!</h1>
      <p className="comoFunciona-description">
        Elimina las excusas y empieza a planificar de la manera más sencilla y eficiente. Habla con tu calendario, solicita agendar reuniones, eventos, tareas y simplemente pidiéndolo.        
      </p>
    </div>
  );
}

export default ComoFunciona;
