export function sanitizeInput(input) {
  if (typeof input !== 'string') {
    return input;
  }
  
  // Eliminar caracteres especiales y etiquetas HTML
  let sanitized = input.replace(/<[^>]*>?/gm, '');
  
  // Escapar caracteres especiales de SQL
  sanitized = sanitized.replace(/['";]/g, '');
  
  // Limitar la longitud del input
  sanitized = sanitized.slice(0, 1000);
  
  return sanitized;
}
