import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import './CallToAction.css';
import WaveBackground from './WaveBackground';

const CallToAction = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/registro');
  };

  const contentVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 10
      }
    }
  };

  return (
    <section className="cta-fullscreen-section">
      <WaveBackground />
      <motion.div
        className="cta-content"
        variants={contentVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.h2 className="cta-main-title" variants={itemVariants}>
          Simplifica la forma de gestionar tu tiempo
        </motion.h2>
        <motion.p className="cta-description" variants={itemVariants}>
          Habla con tu calendario y conviértelo en tu asistente de planificación personal
        </motion.p>

        <motion.div className="cta-button-container" variants={itemVariants}>
          <button className="cta-button" onClick={handleButtonClick}>
            <div className="backdrop">
              <span>Suscríbete a la lista de espera y recibe beneficios</span>
            </div>
            <div className="overlay">
              <span>Sé el primero en probarlo</span>
            </div>
          </button>
        </motion.div>

        <motion.div className="cta-video-wrapper" variants={itemVariants}>
          <div className="video-border">
            <iframe
              className="cta-video-frame"
              src="https://www.youtube.com/embed/wUAkt4rZn6A?autoplay=0&modestbranding=1&controls=1&showinfo=0&rel=0"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube Video"
            ></iframe>
          </div>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default CallToAction;
