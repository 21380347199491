import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import './SecondCallToAction.css';

const SecondCallToAction = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/registro');
  };

  const contentVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  return (
    <section className="second-cta-section">
      <motion.div
        className="second-cta-content"
        variants={contentVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <motion.h2
          className="second-cta-title"
          variants={itemVariants}
        >
          ¿Todavía tienes excusas para planificar?
        </motion.h2>

        <motion.div
          className="second-cta-button-container"
          variants={itemVariants}
        >
          <button className="second-cta-button" onClick={handleButtonClick}>
            <div className="backdrop">
              <span>Suscríbete a la lista de espera y recibe beneficios</span>
            </div>
            <div className="overlay">
              <span>Sé el primero en probarlo</span>
            </div>
          </button>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default SecondCallToAction; 