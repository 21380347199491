import { signInWithPopup, signOut } from 'firebase/auth';
import { auth, googleProvider } from '../firebase';

export const loginWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const emailsAutorizados = [
      'aljerr3@gmail.com',
      'davidavila@gmail.com',
      'adrianacepeda11@gmail.com'
    ];

    if (!emailsAutorizados.includes(result.user.email)) {
      await signOut(auth);
      throw new Error('Email no autorizado');
    }

    return result.user;
  } catch (error) {
    console.error('Error en autenticación:', error);
    throw error;
  }
};

export const logout = () => {
  return signOut(auth);
}; 