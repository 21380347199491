import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "./components/Navbar";
import ComoFunciona from "./components/ComoFunciona";
import Caracteristicas from "./components/Caracteristicas";
import SecondCallToAction from "./components/SecondCallToAction";
import Politicas from "./components/Politicas";
import Footer from "./components/Footer";
import "./style.scss";
import RegistroForm from "./components/RegistroForm";
import CallToAction from "./components/CallToAction";
import { auth } from './firebase';
import Stats from './components/Stats';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/politicas" element={<Politicas />} />
        <Route path="/registro" element={<RegistroForm />} />
        <Route path="/stats" element={<Stats />} />
      </Routes>
    </Router>
  );
}

function Home() {
  return (
    <>
      <Navbar />
      <section className="home" id="callToAction">
        <CallToAction />
      </section>
      <section data-aos="slide-right" className="home" id="caracteristicas">
        <Caracteristicas />
      </section>
      <section className="home" data-aos="fade" id="howWorks">
        <ComoFunciona />
      </section>
      <section className="home" id="beneficios">
        <SecondCallToAction />
      </section>
      <Footer/>
    </>
  );
}

export default App;
