import React, { useState, useEffect } from 'react';
import Logo from '../img/logo.png';
import './navbar.css';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={scrolled ? 'scrolled' : ''}>
      <div className="nav-logo-container">
        <img className="nav_logo" src={Logo} alt="Planius logo" />
      </div>
    </nav>
  );
};

export default Navbar;
