import React, { useEffect, useState, useRef } from 'react';

const WaveBackground = () => {
  const svgRef = useRef(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (svgRef.current) {
        const { left, top, width, height } = svgRef.current.getBoundingClientRect();
        const x = (event.clientX - left) / width;
        const y = (event.clientY - top) / height;
        setMousePosition({ x, y });
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <svg
      ref={svgRef}
      className="wave-background"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 320"
      preserveAspectRatio="none"
      style={{position: 'absolute', bottom: 0, left: 0, width: '100%', height: '50%', zIndex: -1}}
    >
      <defs>
        <linearGradient id="waveGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#e0e7ff" stopOpacity="1" />
          <stop offset="50%" stopColor="#d8b4fe" stopOpacity="0.8" />
          <stop offset="100%" stopColor="#bfdbfe" stopOpacity="1" />
        </linearGradient>
      </defs>
      <path
        fill="url(#waveGradient)"
        d={`
          M0,${80 + mousePosition.y * 25}
          C320,${90 + mousePosition.x * 20},
          720,${70 + mousePosition.y * 30},
          1440,${80 + mousePosition.x * 25}
          V320H0Z
        `}
      >
        <animate
          attributeName="d"
          dur="5s"
          repeatCount="indefinite"
          values={`
            M0,${80 + mousePosition.y * 25}C320,${90 + mousePosition.x * 20},720,${70 + mousePosition.y * 30},1440,${80 + mousePosition.x * 25}V320H0Z;
            M0,${85 + mousePosition.y * 20}C320,${95 + mousePosition.x * 25},720,${75 + mousePosition.y * 25},1440,${85 + mousePosition.x * 20}V320H0Z;
            M0,${80 + mousePosition.y * 25}C320,${90 + mousePosition.x * 20},720,${70 + mousePosition.y * 30},1440,${80 + mousePosition.x * 25}V320H0Z
          `}
        />
      </path>
    </svg>
  );
};

export default WaveBackground;
