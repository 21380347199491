import React from "react";
function Politicas() {
  return (
    <div>
      <div className="cardPolitics">
        <h1>Políticas de seguridad</h1>
        <p>
          <strong>Introducción</strong>
          <br />
          En Planius, estamos comprometidos con la protección de la seguridad y
          la privacidad de los datos de nuestros usuarios. Esta política de
          seguridad describe las medidas técnicas y organizativas que
          implementamos para asegurar la integridad, confidencialidad y
          disponibilidad de toda la información que gestionamos.
        </p>
        <p>
          <strong>Seguridad de los Datos</strong>
          <br />
          <strong>Protección de Datos:</strong> Utilizamos Google Firestore, una
          solución de base de datos altamente segura, para almacenar y gestionar
          todos los datos de nuestros usuarios. Todos los datos están protegidos
          con cifrado tanto en tránsito como en reposo, utilizando estándares de
          cifrado avanzados.
          <br />
          <strong>Acceso a los Datos:</strong> El acceso a los datos está
          estrictamente limitado a personal autorizado de Planius y se rige por
          políticas de control de acceso rigurosas. Los empleados están
          obligados a seguir nuestras políticas de seguridad y se les
          proporciona entrenamiento regular sobre mejores prácticas de
          seguridad.
          <br />
          <strong>Auditoría y Monitoreo:</strong> Realizamos auditorías de
          seguridad regulares y monitoreamos continuamente nuestra
          infraestructura para detectar y responder a incidentes de seguridad de
          manera oportuna.
        </p>
        <p>
          <strong>Gestión de Incidentes</strong>
          <br />
          <strong>Respuesta a Incidentes:</strong> Contamos con un equipo
          dedicado a la respuesta ante incidentes de seguridad, que está
          preparado para actuar rápidamente en caso de una violación de
          seguridad. Los procedimientos de respuesta están bien definidos dentro
          de nuestra organización para asegurar una resolución eficiente y
          minimizar cualquier impacto.
          <br />
          <strong>Notificación de Brechas:</strong> En caso de que se produzca
          una brecha de seguridad que pueda afectar significativamente a
          nuestros usuarios o a la integridad de sus datos, nos comprometemos a
          notificar a los usuarios afectados y a las autoridades competentes de
          acuerdo con las leyes aplicables, en un plazo razonable.
        </p>
        <p>
          <strong>Mejoras Continuas</strong>
          <br />
          En Planius, creemos que la seguridad es un proceso continuo. Nos
          comprometemos a revisar y actualizar nuestras políticas y prácticas de
          seguridad regularmente para mejorar nuestra resiliencia a las amenazas
          de seguridad y para cumplir con los estándares de la industria.
        </p>
        <p>
          <strong>Compromiso de los Usuarios</strong>
          <br />
          Instamos a todos nuestros usuarios a contribuir a la seguridad general
          de la plataforma, utilizando contraseñas fuertes, manteniendo la
          confidencialidad de sus credenciales de acceso, y notificando
          cualquier actividad sospechosa o inusual a nuestro equipo de soporte.
        </p>
        <p>
          <strong>Contacto</strong>
          <br />
          Si tiene preguntas sobre nuestra política de seguridad, o si desea
          informar de un problema de seguridad, por favor contacte con nosotros
          a través de \[contacto@planius.com\].
        </p>
        <h1>Acceso al Calendario de Google</h1>
        <p>
          En Planius, solicitamos acceso al calendario de Google de nuestros
          usuarios con el fin de proporcionar funcionalidades clave de nuestra
          aplicación y mejorar la experiencia del usuario. A continuación,
          explicamos en detalle por qué necesitamos este acceso y cómo manejamos
          los datos del calendario.
        </p>
        <p>
          <strong>Razones para Acceder al Calendario</strong>
          <br />
          1. <strong>Creación de Eventos:</strong> Nuestra aplicación permite a
          los usuarios crear eventos directamente desde la plataforma y
          agregarlos a su calendario de Google. Esto proporciona una integración
          perfecta y elimina la necesidad de que los usuarios ingresen
          manualmente los eventos en su calendario después de crearlos en
          Planius.
          <br />
          2. <strong>Sincronización de Eventos:</strong> Con acceso al
          calendario de Google, podemos sincronizar automáticamente los eventos
          entre Planius y el calendario del usuario. Esto asegura que los
          usuarios tengan una vista actualizada de todos sus compromisos, tanto
          los creados en Planius como los añadidos directamente en su calendario
          de Google.
          <br />
          3. <strong>Evitar Conflictos de Programación:</strong> Al acceder al
          calendario de los usuarios, podemos verificar si hay conflictos de
          programación al crear nuevos eventos. Esto ayuda a los usuarios a
          evitar la doble reserva y asegura que siempre estén al tanto de sus
          compromisos.
          <br />
          4. <strong>Personalización y Recomendaciones:</strong> Analizando los
          datos del calendario (sin identificar a individuos específicos),
          podemos entender mejor los patrones de uso y las preferencias de
          nuestros usuarios. Esto nos permite personalizar la experiencia de la
          aplicación y proporcionar recomendaciones relevantes, como sugerir
          horas de reunión basadas en la disponibilidad del usuario.
        </p>
        <p>
          <strong>Manejo de Datos del Calendario</strong>
          <br />
          Nos tomamos muy en serio la privacidad y la seguridad de los datos del
          calendario de nuestros usuarios: 1.{" "}
          <strong>Consentimiento del Usuario:</strong> Solicitamos
          explícitamente el consentimiento del usuario antes de acceder a su
          calendario. Los usuarios tienen control total para conceder o revocar
          el acceso en cualquier momento desde su configuración de cuenta de
          Google.
          <br />
          2. <strong>Acceso Limitado:</strong> Nuestro acceso al calendario está
          estrictamente limitado a las funcionalidades necesarias mencionadas
          anteriormente. No accedemos, leemos o almacenamos ningún otro dato del
          calendario que no sea esencial para estas funciones.
          <br />
          3. <strong>Cifrado y Almacenamiento Seguro:</strong> Todos los datos
          del calendario se transmiten de forma segura usando cifrado SSL/TLS y
          se almacenan utilizando fuertes medidas de seguridad, incluyendo el
          cifrado en reposo.
          <br />
          4. <strong>Retención de Datos:</strong> Sólo conservamos los datos del
          calendario durante el tiempo necesario para proporcionar nuestros
          servicios. Si un usuario desactiva la integración del calendario o
          cierra su cuenta, eliminamos todos los datos del calendario asociados
          de acuerdo con nuestra política de retención de datos.
          <br />
          5. <strong>No Comercialización:</strong> Nunca vendemos, alquilamos o
          comercializamos los datos del calendario de nuestros usuarios a
          terceros. Los datos se utilizan únicamente para proporcionar y mejorar
          nuestros servicios.
        </p>
        <p>
          Nos comprometemos a ser transparentes sobre nuestras prácticas de
          manejo de datos y regularmente revisamos y actualizamos nuestras
          políticas para asegurar que cumplimos con los más altos estándares de
          privacidad y seguridad. Si tiene alguna pregunta sobre cómo manejamos
          los datos del calendario, no dude en ponerse en contacto con nosotros.
        </p>
        <p>
          <h1>Acceso a Google Tasks</h1>
          <br />
          En Planius, también solicitamos acceso a Google Tasks de nuestros
          usuarios para proporcionar una integración completa con nuestra
          aplicación. Esto permite a los usuarios ver, crear y gestionar sus
          tareas directamente desde Planius. Aplicamos los mismos principios de
          privacidad y seguridad descritos anteriormente para el acceso al
          Calendario de Google. Sólo accedemos a los datos de Google Tasks
          necesarios para proporcionar esta funcionalidad y nunca compartimos o
          utilizamos estos datos para ningún otro propósito.
        </p>
        Después de esa nueva sección, agrega otra para "Inicio de Sesión con
        Microsoft":
        <p>
          <strong>Inicio de Sesión con Microsoft</strong>
          <br />
          Ofrecemos la opción de iniciar sesión en Planius utilizando una cuenta
          de Microsoft para la comodidad de nuestros usuarios. Cuando un usuario
          elige esta opción, recibimos información básica del perfil, como el
          nombre y la dirección de correo electrónico, de Microsoft. No
          accedemos a ningún otro dato asociado con la cuenta de Microsoft del
          usuario. Estos datos de perfil sólo se utilizan para autenticar al
          usuario y personalizar su experiencia en Planius. No compartimos,
          vendemos o utilizamos estos datos para ningún otro fin.
        </p>
        Finalmente, puedes reforzar el compromiso de no utilizar los datos del
        usuario para otros fines añadiendo lo siguiente al final, antes de la
        sección "Contacto":
        <p>
          <strong>Nuestro Compromiso</strong>
          <br />
          En Planius, nos comprometemos a utilizar los datos de nuestros
          usuarios única y exclusivamente para proporcionar, mejorar y
          personalizar nuestros servicios. Nunca utilizaremos los datos del
          Calendario de Google, Google Tasks, la información de perfil de
          Microsoft, o cualquier otro dato del usuario para ningún propósito que
          no esté directamente relacionado con el funcionamiento de Planius.
          Esto incluye, pero no se limita a, la venta de datos a terceros, la
          publicidad dirigida, o cualquier otra forma de comercialización de
          datos. La privacidad y la confianza de nuestros usuarios son de suma
          importancia para nosotros, y nos esforzamos por mantener los más altos
          estándares de protección de datos.
        </p>
        <h2>Pagos</h2>
        <p>
          Podemos ofrecer productos y/o servicios de pago dentro del servicio.
          En ese caso, utilizamos servicios de terceros para el procesamiento de
          pagos (por ejemplo, procesadores de pagos). No almacenaremos ni
          recopilaremos los detalles de su tarjeta de pago. Esa información se
          proporciona directamente a nuestros procesadores de pagos externos,
          cuyo uso de su información personal se rige por su política de
          privacidad. Estos procesadores de pago se adhieren a los estándares
          establecidos por PCI-DSS gestionados por el PCI Security Standards
          Council. Los procesadores de pagos con los que trabajamos son Stripe.
          Su política de privacidad se puede ver en:
          https://stripe.com/us/privacy.
        </p>
        <h2>Privacidad de los Niños</h2>
        <p>
          Nuestros servicios no están dirigidos a menores de 18 años ("Niños").
          No recopilamos a sabiendas información de identificación personal de
          niños menores de 18 años. Si nos enteramos de que hemos recopilado
          datos personales de niños sin la verificación del consentimiento de
          los padres, tomamos medidas para eliminar esa información de nuestros
          servidores.
        </p>
        <h2>Nuestro Compromiso</h2>
        <p>
          En Planius, nos comprometemos a utilizar los datos de nuestros
          usuarios única y exclusivamente para proporcionar, mejorar y
          personalizar nuestros servicios. Nunca utilizaremos datos para ningún
          propósito que no esté directamente relacionado con el funcionamiento
          de Planius.
        </p>
        <h2>Contacto</h2>
        <p>
          Si tiene preguntas sobre nuestra política de privacidad y seguridad, o
          si desea informar de un problema, por favor contacte con nosotros a
          través de [contacto@planius.com].
        </p>
      </div>
    </div>
  );
}
export default Politicas;
