import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './RegistroForm.css';
import logo from '../img/logo.png';
import FormularioInicial from './FormularioInicial';
import { db } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { sanitizeInput } from '../utils/security';

const RegistroForm = () => {
  const navigate = useNavigate();
  const [mostrarPreguntas, setMostrarPreguntas] = useState(false);
  const [nombre, setNombre] = useState('');
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    edad: '',
    pais: '',
    industria: '',
    organizacion: '',
    herramientas: '',
    frustraciones: '',
    impacto: '',
    bienestar: '',
    comentarios: '',
  });
  const [contador, setContador] = useState(5);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInitialSubmit = async (initialData) => {
    setError('');
    setIsLoading(true);
    try {
      const sanitizedData = Object.keys(initialData).reduce((acc, key) => {
        acc[key] = sanitizeInput(initialData[key]);
        return acc;
      }, {});

      const docRef = doc(db, "respuestas", sanitizedData.email);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setError('Este email ya está registrado.');
        setIsLoading(false);
        return;
      }

      await setDoc(docRef, sanitizedData);
      setNombre(sanitizedData.nombre.split(' ')[0]);
      setFormData({ ...formData, ...sanitizedData });
      setMostrarPreguntas('decision');
    } catch (e) {
      console.error("Error al agregar documento inicial: ", e);
      setError('Hubo un error al registrar. Por favor, intenta de nuevo.');
    }
    setIsLoading(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const sanitizedData = Object.keys(formData).reduce((acc, key) => {
        acc[key] = sanitizeInput(formData[key]);
        return acc;
      }, {});

      const userDocRef = doc(db, "respuestas", sanitizedData.email);
      
      // Guardamos las preguntas adicionales en el mismo documento del usuario
      await setDoc(userDocRef, {
        ...sanitizedData,
        preguntasAdicionales: {
          organizacion: sanitizedData.organizacion,
          herramientas: sanitizedData.herramientas,
          frustraciones: sanitizedData.frustraciones,
          impacto: sanitizedData.impacto,
          bienestar: sanitizedData.bienestar,
          comentarios: sanitizedData.comentarios
        }
      }, { merge: true });

      console.log("Documento actualizado con éxito: ", sanitizedData);
      setMostrarPreguntas('agradecimiento');
    } catch (e) {
      console.error("Error al actualizar documento: ", e);
      setError('Hubo un error al enviar las respuestas adicionales. Por favor, intenta de nuevo.');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (mostrarPreguntas === 'agradecimiento') {
      const timer = setInterval(() => {
        setContador((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            navigate('/');  // Redirigir a la ruta principal
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [mostrarPreguntas, navigate]);

  return (
    <div>
      {error && <div className="error-message">{error}</div>}
      {mostrarPreguntas === false ? (
        <FormularioInicial onSubmit={handleInitialSubmit} isLoading={isLoading} />
      ) : mostrarPreguntas === 'decision' ? (
        <div className="decision-container">
          <div className="logo-container">
            <img src={logo} alt="Logo" className="logo" />
          </div>
          <div className="check-icon">
            <i className="fas fa-check-circle"></i>
          </div>
          <p>¡<strong>{nombre}</strong>, te has suscrito a nuestra lista de espera!</p>
          <p>Si te interesa optar por descuentos al momento del lanzamiento, nos podrías ayudar respondiendo estas preguntas adicionales.</p>
          <button className="highlight-button" onClick={() => setMostrarPreguntas(true)}>Responder preguntas adicionales</button>
          <button onClick={() => navigate('/')}>Volver</button>
        </div>
      ) : mostrarPreguntas === 'agradecimiento' ? (
        <div className="agradecimiento-container">
          <p>¡Gracias por responder, recibirás descuentos adicionales al momento del lanzamiento!</p>
          <p>Serás redireccionado en <span className="contador">{contador}</span></p>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="registro-form">
          <img src={logo} alt="Planius Logo" />
          <div>
            <label>Piensa en un día típico de tu semana. ¿Cuántas veces te detienes a organizar tus tareas proyectos antes de empezar?</label>
            <textarea name="organizacion" value={formData.organizacion} onChange={handleChange} required />
          </div>
          <div>
            <label>¿Qué herramientas o métodos sueles usar para mantener todo en orden?</label>
            <textarea name="herramientas" value={formData.herramientas} onChange={handleChange} required />
          </div>
          <div>
            <label>Imagina que estás tratando de organizar tu semana. ¿Qué es lo que más te cuesta o te frustra en ese momento?</label>
            <textarea name="frustraciones" value={formData.frustraciones} onChange={handleChange} required />
          </div>
          <div>
            <label>Sin una buena planificación, ¿cómo sientes que te afecta tu capacidad para cumplir con tus objetivos y tareas diarias?</label>
            <textarea name="impacto" value={formData.impacto} onChange={handleChange} required />
          </div>
          <div>
            <label>Cuando no logras organizar tus proyectos, ¿cómo influye esto en tu estado de ánimo y bienestar mental?</label>
            <textarea name="bienestar" value={formData.bienestar} onChange={handleChange} required />
          </div>
          <div>
            <label>¿Hay algo más que te gustaría compartir sobre tu experiencia con la planificación?</label>
            <textarea name="comentarios" value={formData.comentarios} onChange={handleChange} required />
          </div>
          <button type="submit" disabled={isLoading}>
            {isLoading ? <span className="spinner"></span> : 'Enviar'}
          </button>
        </form>
      )}
    </div>
  );
};

export default RegistroForm;
